<template>
  <div>
    <a-tooltip
      placement="top"
      class="pr-0"
    >
      <template #title>
        Create New
      </template>
      <button
        class="btn btn-primary mb-3"
        @click="add = true"
      >
        Create New
      </button>
    </a-tooltip>
    <div class="table-responsive">
      <table class="table table-hover text-nowrap">
        <thead>
          <tr>
            <th style="width: 20%">
              SBC Name
            </th>
            <th style="width: 20%">
              SBC IPs
            </th>
            <th style="width: 30%">
              Status
            </th>
            <th style="width: 30%">
              Created ON
            </th>
            <th width="120px">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="add">
            <td>
              <a-input v-model:value="newItem.name" />
            </td>
            <td class="d-flex flex-row-reverse">
              <a-tooltip
                placement="top"
                class="pr-0"
              >
                <template #title>
                  Add
                </template>
                <a
                  class="a_action_icon d-inline-block bg-secondary mb-2 ml-2"
                  @click="addInput"
                >
                  <i
                    class="fa fa-plus"
                    aria-hidden="true"
                  />
                </a>
              </a-tooltip>

              <div class="d-flex flex-column">
                <div
                  v-for="(find, index) in newItem.ips"
                  :key="index"
                >
                  <vue-ip-input
                    :ip="find.ip"
                    :on-change="onIpChange"
                  />
                </div>
              </div>
            </td>
            <td />
            <td />
            <td class="d-flex">
              <a-tooltip
                placement="top"
                class="pr-0"
              >
                <template #title>
                  Save
                </template>
                <a
                  class="a_action_icon mb-2 mr-2"
                  @click="addNewItem"
                >
                  <i
                    class="fa fa-floppy-o"
                    aria-hidden="true"
                  />
                </a>
              </a-tooltip>
              <a-tooltip
                placement="top"
                class="pr-0"
              >
                <template #title>
                  Cancel
                </template>
                <a
                  class="a_action_icon mb-2 mr-2"
                  @click="add = false"
                >
                  <i
                    class="fa fa-times"
                    aria-hidden="true"
                  />
                </a>
              </a-tooltip>
            </td>
          </tr>
          <tr
            v-for="item in items"
            :key="item.id"
          >
            <td>
              <template v-if="!item.edit">
                {{ item.name }}
              </template>
              <a-input
                v-else
                v-model:value="editItem.name"
              />
            </td>
            <!-- <td>{{ item.protocol }}</td> -->
            <td>
              <div
                v-for="(i, index) in item.ips"
                :key="index"
              >
                <template v-if="!item.edit">
                  {{ item.ips.length? item.ips[index].ip : '' }}
                </template>
              </div>
              <template v-if="item.edit">
                <div class="d-flex flex-row-reverse">
                  <a-tooltip
                    placement="top"
                    class="pr-0"
                  >
                    <template #title>
                      Add
                    </template>
                    <a
                      class="a_action_icon d-inline-block bg-secondary mb-2 ml-2"
                      @click="addEditInput"
                    >
                      <i
                        class="fa fa-plus"
                        aria-hidden="true"
                      />
                    </a>
                  </a-tooltip>
                  <div>
                    <div
                      v-for="(find, index) in editItem.ips"
                      :key="index"
                    >
                      <vue-ip-input
                        :ip="find.ip"
                      />
                    </div>
                  </div>
                </div>
              </template>
            </td>
            <!-- <td>item</td> -->
            <td>{{ item.active ? 'Active' : 'Inactive' }}</td>
            <td>{{ getDate(item.created_on) }}</td>
            <td class="d-flex">
              <template v-if="!item.edit">
                <a-tooltip
                  placement="top"
                  class="pr-0"
                >
                  <template #title>
                    Edit
                  </template>
                  <a
                    class="a_action_icon bg-secondary mb-2 mr-2"
                    @click="handleEdit(item)"
                  >
                    <i
                      class="fa fa-pencil"
                      aria-hidden="true"
                    />
                  </a>
                </a-tooltip>


                <a-tooltip
                  placement="top"
                  class="pr-0"
                >
                  <template #title>
                    Inactive
                  </template>
                  <a
                    v-if="!item.active"
                    class="a_action_icon bg-danger mb-2 mr-2"
                    @click="updateActivity(item)"
                  >
                    <i
                      class="fa fa-check"
                      aria-hidden="true"
                    />
                  </a>
                </a-tooltip>


                <a-tooltip
                  placement="top"
                  class="pr-0"
                >
                  <template #title>
                    Active
                  </template>
                  <a
                    v-if="item.active"
                    class="a_action_icon bg-success mb-2 mr-2"
                    @click="updateActivity(item)"
                  >
                    <i
                      class="fa fa-check"
                      aria-hidden="true"
                    />
                  </a>
                </a-tooltip>


                <a-tooltip
                  placement="top"
                  class="pr-0"
                >
                  <template #title>
                    Delete
                  </template>
                  <a
                    class="a_action_icon bg-secondary mb-2 mr-2"
                    @click="deleteItem(item)"
                  >
                    <i
                      class="fa fa-trash"
                      aria-hidden="true"
                    />
                  </a>
                </a-tooltip>
              </template>
              <template v-else>
                <a-tooltip
                  placement="top"
                  class="pr-0"
                >
                  <template #title>
                    Save
                  </template>
                  <a
                    class="a_action_icon mb-2 mr-2"
                    @click="handleSaveItem(item)"
                  >
                    <i
                      class="fa fa-floppy-o"
                      aria-hidden="true"
                    />
                  </a>
                </a-tooltip>
                <a-tooltip
                  placement="top"
                  class="pr-0"
                >
                  <template #title>
                    Cancel
                  </template>
                  <a
                    class="a_action_icon mb-2 mr-2"
                    @click="item.edit = false"
                  >
                    <i
                      class="	fa fa-times"
                      aria-hidden="true"
                    />
                  </a>
                </a-tooltip>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
      <a-pagination
        v-if="total"
        class="pagination-right"
        :default-current="1"
        :total="total"
        @change="pagination($event)"
      />
    </div>
  </div>
</template>

<script>
    import VueIpInput from 'vue-ip-input'
    import axios from '@/axios'
    import moment from '@/plugins/moment'
    import {Modal, notification} from "ant-design-vue";

    export default {
        components: {
            'vue-ip-input': VueIpInput
        },
        data() {
            return {
                items: null,
                add: false,
                total: 0,
                editItem: {
                    name: null,
                    ips: [{
                        ip: '',
                        active: true
                    }],
                },
                ip: '',
                newItem: {
                    name: '',
                    ips: [{
                        ip: '',
                        active: true
                    }],
                },
            }
        },

        mounted() {
            this.loadItems();
        },

        methods: {
          onIpChange(ip) {
            this.newItem.ips.map(el => {
              el.ip = ip
              el.active = true
            })
          },
          pagination(int) {
            this.skip = 0
            this.skip += this.step * int - this.step
            this.loadItems()
          },
            addInput() {
                this.newItem.ips.push({ip: '', active: true});
            },

            addEditInput() {
                this.editItem.ips.push({ip: '', active: true});
            },
            addNewItem() {
                const params = {
                    name: this.newItem.name,
                    // ips: [{ip: this.newItem.ip, active: true}],
                    ips: this.newItem.ips.filter(function (e) {
                        return e.ip !== ''
                    }).map((e) => {
                        return {
                            ip: e.ip,
                            active: true
                        }
                    }),
                    active: true
                };

                axios
                    .post('/signing/sbc', params)
                    .then(() => {
                      this.newItem.name = '',
                      this.ips= [{
                          ip: '',
                          active: true
                      }]
                    })
                    .catch(() => {}).finally(() => {
                    this.newItem.name = '';
                    this.newItem.ip = '';
                    this.add = false
                    this.loadItems()
                })
            },
            handleSaveItem(item) {
                let param = {
                    name: this.editItem.name,
                    ips: [{ip: this.editItem.ip, active: true}],
                    // ips: this.editItem.ips.filter(function (e) {
                    //     return e.ip !== ''
                    // }).map((e) => {
                    //     return {
                    //         ip: e.ip,
                    //         active: true
                    //     }
                    // }),
                    active: true
                }
                axios
                    .put(`/signing/sbc/${item.id}`, param)
                    .then(() => {}).catch(() => {}).finally(() => {
                      item.edit = false
                      this.loadItems()
                    })
            },

            deleteItem(item) {
                const vm = this
                Modal.confirm({
                    title: 'Do you Want to delete this item?',
                    onOk() {
                        vm.deleteReq(item)
                    },
                    onCancel() {},
                    class: 'test',
                })
            },

            deleteReq(item) {
                axios
                    .delete(`/signing/sbc/${item.id}`)
                    .then(() => {
                        this.$store.dispatch('deleted', item.name)
                        this.loadItems()
                    }).catch(() => {})
            },

            updateActivity(item) {
                const vm = this
                Modal.confirm({
                    title: `Do you Want to ${item.active ? 'Inactivate' : 'Activate'} this item?`,
                    onOk() {
                        item.active = !item.active
                        axios
                            .put(`/signing/sbc/${item.id}`, item)
                            .then((res) => {
                                if (res.data.active === true) {
                                    notification.success({
                                        message: 'Your SB has been activated',
                                    })
                                } else {
                                    notification.success({
                                        message: 'Your SB has been deactivated',
                                    })
                                }
                            })
                            .catch(() => {})
                    },
                    onCancel() {},
                    class: 'test',
                })
            },


            handleEdit(item) {
                item.edit = true;
                this.editItem.name = item.name;
                // this.editItem.ip = item.ips[0].ip;
                this.editItem.ips = item.ips.map((e) => {
                    return {
                        ip: e.ip,
                        active: true
                    }
                })
            },

            loadItems() {
                axios
                    .get('/signing/sbc?take=10&skip=0')
                    .then((res) => {
                        this.items = res.data.data
                        this.total = res.data.count > this.step ? res.data.count : res.data.count
                    }).catch(() => {})
            },
            getDate(date) {
                return moment(date)
            },
        }

    }
</script>

<style>
    .ip-input-container {
        display: inline-block;
        height: 32px !important;
        width: 100% !important;
        font-variant: tabular-nums !important;
        list-style: none !important;
        font-feature-settings: 'tnum' !important;
        position: relative !important;
        padding: 4px 11px !important;
        color: #595c97 !important;
        font-size: 14px !important;
        line-height: 1.5715 !important;
        background-color: #ffffff !important;
        background-image: none !important;
        border: 1px solid #e4e9f0 !important;
        border-radius: 8px !important;
        transition: all 0.3s !important;
    }

    .ip-input-container:hover {
        border-color: #78a3ff !important;
        border-right-width: 1px !important;
    }

    .pagination-right {
        text-align: right !important;
        margin-right: 86px !important;
    }
</style>